.footer-wrap {
    padding: 4.1vw 13.5vw;
    border-top: 1px solid #ffffff4c;
    > .body {
        display: flex;
        flex-direction: row;
        > .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            > a {
                margin-bottom: 2vw;
                > img {
                    width: 8.6vw;
                }
            }
            > .info {
                color: #fff;
                flex: 1;
                font-size: 0.73vw;
                line-height: 0.98vw;
            }
            > .copy {
                color: #fff;
                font-size: 0.73vw;
                line-height: 0.98vw;
                margin-bottom: 1vw;
            }
        }
        > .right {
            > .contact-wrap {
                > .title {
                    font-family: 'NanumSquareEB';
                    color: #fff;
                    font-size: 0.93vw;
                    margin-bottom: 1vw;
                }
                > .sub-title {
                    color: #fff;
                    font-size: 0.73vw;
                    line-height: 1vw;
                    margin-bottom: 0.26vw;
                }
                > .email {
                    display: block;
                    color: #523ee8;
                    font-size: 0.73vw;
                    line-height: 1vw;
                    margin-bottom: 1.3vw;
                }
                > .mobile {
                    display: none;
                }
                > .copy {
                    display: none;
                }
                margin-bottom: 3.4vw;
            }
            > .sns-wrap {
                > a {
                    display: inline-block;
                    width: 4.1vw;
                    height: 4.1vw;
                    & + a {
                        margin-left: 3.1vw;
                    }
                    &.twitter {
                        background-image: url(../../assets/images/drawable-xxhdpi/twitter.png);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        &:hover {
                            background-image: url(../../assets/images/drawable-xxhdpi/twitter_on.png);
                        }
                    }
                    &.telegram {
                        background-image: url(../../assets/images/drawable-xxhdpi/telegram.png);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        &:hover {
                            background-image: url(../../assets/images/drawable-xxhdpi/telegram_on.png);
                        }
                    }
                    &.medium {
                        background-image: url(../../assets/images/drawable-xxhdpi/medium.png);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        &:hover {
                            background-image: url(../../assets/images/drawable-xxhdpi/medium_on.png);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .footer-wrap {
        padding: 5.3vw 5.6vw;
        border-top: none;
        > .body {
            > .left {
                display: none;
            }
            > .right {
                display: flex;
                flex: 1;
                flex-direction: row;
                > .contact-wrap {
                    flex: 1;
                    > .title {
                        font-size: 3.2vw;
                        margin-bottom: 3.2vw;
                    }
                    > .sub-title {
                        font-size: 2.6vw;
                        line-height: 3.7vw;
                        margin-bottom: 1.3vw;
                        display: inline-block;
                        width: 32vw;
                    }
                    > .email {
                        font-size: 2.6vw;
                        line-height: 3.7vw;
                        margin-bottom: 1.3vw;
                        display: inline-block;
                        &:before {
                            content: "|";
                            color: #ffffff4c;
                            margin-right: 5.8vw;
                        }
                    }
                    > .mobile {
                        display: block;
                    }
                    > .copy {
                        display: block;
                        font-size: 2.6vw;
                        line-height: 3.7vw;
                        color: #fff;
                        margin-top: 5.3vw;
                    }
                }
                > .sns-wrap {
                    > a {
                        display: block;
                        width: 6.4vw;
                        height: 6.4vw;
                        & + a {
                            margin-left: 0vw;
                            margin-top: 4vw;
                        }
                    }
                }
            }
        }
    }
}